// To support rolling out search for Algolia
// to a single brand
.search-group-container {
    visibility: hidden;
    height: 0px;
}

.is-sticky {
    .search-group-container {
        button.open {
            visibility: visible;
            height: auto;
        }
    }
}