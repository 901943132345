@import "auto-complete.scss";
@import "recent-searches.scss";
@import "legacy-search.scss";

.aa-Panel {
    z-index: 999;
    margin-top: 0px;
    border-radius: 0px;

    .ui-container {
        @media only screen and (min-width: 768px) {
            display: flex;
            gap: 15px;
        }
    
        .aa-Item[aria-selected=true] {
            background-color: #D7E0EE;
        }

        .aa-SourceHeader {
            text-transform: uppercase;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
            font-size: 14px;
            white-space: nowrap;
        }
        
    
        .ui-plugins {
            .aa-SourceHeader {
                min-width: 200px;
            }
        }

        .view-all-items-container {
            display: block;
            margin: 10px 0px 0px 82px;
           
            a {
                text-decoration: underline;
                color: inherit;
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .results-image {
            width: 60px;
            min-width: 70px;
            height: 60px;
            box-shadow: none;
        
            img {
                height: 60px;
                max-width: none;
                max-height: none;
            }
        }

        .quick-link {
            display: flex;
        }
        
    }
}