#recent-searches {
    .aa-ItemActions button:nth-child(1) {
        svg {
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 4L4 12' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4 4L12 12' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            path {
                display: none;
            }
        }
    }
    .aa-ItemActions button:nth-child(2),
    .aa-ItemIcon {
        display: none;
    }
    .aa-ItemContentTitle {
        mark {
            font-weight: 400;
        }
    }
}