#algolia-autocomplete {
    display: flex;
    flex-direction: column;

    .aa-Form {
        border-radius: 0px;
        border-color: #ccc;
        box-shadow: none;
    }

    .aa-InputWrapperPrefix {
        order: 3;

        label {
            margin-bottom: 0px;
        }

        button {
            padding: 0px;
        }
    }

    .aa-InputWrapper {
        order: 1;
        padding-left: 15px;
    }

    .aa-InputWrapperSuffix {
        order: 2;

        svg {
            path {
                fill: #ccc !important;
            }
        }

        button {
            border-right: 1px solid #ccc;
        }
    }
}